import { Box, Group, LoadingOverlay } from '@mantine/core';
import { indexBy, prop, sum } from 'ramda';
import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useAllocationRunCustomerOutputData, useAllocationRunStdDeliveryOutputData } from 'amp/store/allocationRuns/hooks';
import { getAllocationRunStartAndEnd } from 'amp/store/allocationRuns/selectors';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import BaseChart from 'shared/components/Chart/baseChart';
import UtcDatePicker from 'shared/components/DatePicker/utcDatePicker';
import BasePaper from 'shared/components/Paper/basePaper';
import { getCurrentUser } from 'shared/store/user/selectors';
import { isCfeFuel } from 'shared/types/generator';
import { dateToMidnightUTC, getLastYearStart, numberOfDaysBetween } from 'shared/utils/dates';
import { useAppSelector } from 'store';


// takes a number between 0 - 100 and returns the color of that point
const getColorFromScore = (cfeScore: number) => {
  if (cfeScore > 50) {
    return '#62c1ab';
  } else if (cfeScore > 20) {
    return '#a8d9ce';
  } else if (cfeScore > 10) {
    return '#dee2e6';
  } else if (cfeScore > 5) {
    return '#868686';
  } else {
    return '#5d5f66';
  }
}

const HourlyCfeScoreChart = () => {
  const { runId = '' } = useParams<{ runId: string }>();
  const [params] = useSearchParams();
  const oci = useAppSelector(getViewingOpCoId);

  const defaultStart = getLastYearStart();
  const startAndEnd = useAppSelector(s => getAllocationRunStartAndEnd(s, runId));
  const startDateStr = params.get('cfes') || (startAndEnd?.start || defaultStart).toISOString();
  const startDate = useMemo(() => new Date(startDateStr), [startDateStr]);

  const customerId = params.get('c');
  const currentCustomerId = useAppSelector(getCurrentUser)?.customer_id;
  const customerRes = useAllocationRunCustomerOutputData(runId, oci || currentCustomerId, customerId);
  const hourlyResults = useMemo(() => customerRes.data?.hourly_results || [], [customerRes]);
  const hourlyResultsByHour = indexBy(prop('datetime'), hourlyResults);

  const stdDeliveryRes = useAllocationRunStdDeliveryOutputData(runId, oci || currentCustomerId);
  const stdDeliveryResult = stdDeliveryRes.data;
  const stdDelivery = stdDeliveryResult?.hourly_results;
  const stdDeliveryResultsByHour = indexBy(prop('hour'), stdDelivery || []);

  const allocationLengthDays = (startAndEnd?.start && startAndEnd?.end) ? numberOfDaysBetween(startAndEnd.start, startAndEnd.end) : 0;

  const hourlyCfeScoreData = useMemo(() => {
    const chartData: (number | string | null)[][] = [];
    if (allocationLengthDays < 30) {
      return chartData;
    }

    // always show 30 days following start date.
    for (let d = 0; d < 30; d++) {
      const currentDate = new Date(startDateStr);
      currentDate.setDate(currentDate.getDate() + d);

      // always show 12 values per day (2hr weighted avg per value)
      for (let h = 0; h < 12; h++) {
        const currentHour = dateToMidnightUTC(currentDate);
        currentHour.setUTCHours(currentHour.getUTCHours() + (h * 2));
        // string formats need to match whats in std results
        const formattedIsoString = currentHour.toISOString().replace('.000Z', '+00:00');
        const stdResult = stdDeliveryResultsByHour[formattedIsoString];
        const customerResult = hourlyResultsByHour[formattedIsoString];

        const nextHour = dateToMidnightUTC(currentDate);
        nextHour.setUTCHours(nextHour.getUTCHours() + (h * 2) + 1);
        const nextHourFormattedIsoString = nextHour.toISOString().replace('.000Z', '+00:00');
        const nextHourStdResult = stdDeliveryResultsByHour[nextHourFormattedIsoString];
        const nextHourCustomerResult = hourlyResultsByHour[nextHourFormattedIsoString];

        const totalConsumption2HrsWh = (customerResult?.consumption_wh || 0) + (nextHourCustomerResult?.consumption_wh || 0);
        const totalProgGenFirstHr = customerResult ? sum(customerResult.subscription_program_generation.map(subRes => subRes.allocated_wh)) : 0;
        const totalProgGenSecondHr = nextHourCustomerResult ? sum(nextHourCustomerResult.subscription_program_generation.map(subRes => subRes.allocated_wh)) : 0;
        const totalProgGen2HrsWh = totalProgGenFirstHr + totalProgGenSecondHr;
        if (totalConsumption2HrsWh === 0) {
          continue; // leave blank spaces in the chart for hours with not consumption
        } else if (totalConsumption2HrsWh <= totalProgGen2HrsWh) {
          chartData.push([d, h, 100]);
        } else {
          let totalGridCfeFirstHrMWh = 0;
          let totalGridNonCfeFirstHrMwh = 0;
          Object.entries(stdResult.mix).forEach(([fuel, { mwh }]) => {
            if (isCfeFuel(fuel)) {
              totalGridCfeFirstHrMWh += mwh;
            } else {
              totalGridNonCfeFirstHrMwh += mwh;
            }
          });
          const gridNonCfePercentFirstHour = totalGridNonCfeFirstHrMwh / ((totalGridNonCfeFirstHrMwh + totalGridCfeFirstHrMWh) || 1);
          const firstHrGridConsumptionWh = (customerResult?.consumption_wh || 0) - totalProgGenFirstHr;
          const gridNonCfe = firstHrGridConsumptionWh * gridNonCfePercentFirstHour;
          const firstHourNonCfeWh = firstHrGridConsumptionWh > 0 ? gridNonCfe : 0;

          let totalGridCfeSecondHrMWh = 0;
          let totalGridNonCfeSecondHrMwh = 0;
          Object.entries(nextHourStdResult.mix).forEach(([fuel, { mwh }]) => {
            if (isCfeFuel(fuel)) {
              totalGridCfeSecondHrMWh += mwh;
            } else {
              totalGridNonCfeSecondHrMwh += mwh;
            }
          });
          const gridNonCfePercentSecondHour = totalGridNonCfeSecondHrMwh / ((totalGridCfeSecondHrMWh + totalGridNonCfeSecondHrMwh) || 1);
          const secondHrGridConsumptionWh = (nextHourCustomerResult?.consumption_wh || 0) - totalProgGenSecondHr;
          const gridNonCfeNextHour = secondHrGridConsumptionWh * gridNonCfePercentSecondHour;
          const secondHourNonCfeWh = secondHrGridConsumptionWh > 0 ? gridNonCfeNextHour : 0;

          const percentCfeBothHours = (totalConsumption2HrsWh - firstHourNonCfeWh - secondHourNonCfeWh) / (totalConsumption2HrsWh || 1);
          chartData.push([d, h, percentCfeBothHours * 100]);
        }
      }
    }

    return chartData;
  }, [stdDeliveryResultsByHour, hourlyResultsByHour, startDateStr, allocationLengthDays]);

  const clockChartHourlyAverages = useMemo(() => {
    if (allocationLengthDays <= 30) {
      return [];
    }

    const totalsByHour: Record<number, { consumedWh: number, cfeWh: number }> = {};
    // always average 30 days following start date.
    for (let d = 0; d < 30; d++) {
      const currentDate = new Date(startDateStr);
      currentDate.setDate(currentDate.getDate() + d);

      for (let h = 0; h < 24; h++) {
        const currentHour = dateToMidnightUTC(currentDate);
        currentHour.setUTCHours(currentHour.getUTCHours() + h);
        const formattedIsoString = currentHour.toISOString().replace('.000Z', '+00:00');

        if (!totalsByHour[h]) {
          totalsByHour[h] = { consumedWh: 0, cfeWh: 0 };
        }

        const stdResult = stdDeliveryResultsByHour[formattedIsoString];
        const customerResult = hourlyResultsByHour[formattedIsoString];
        const totalHourProgGenWh = customerResult ? sum(customerResult.subscription_program_generation.map(subRes => subRes.allocated_wh)) : 0;

        let totalGridCfeMWh = 0;
        let totalGridNonCfeMWh = 0;
        Object.entries(stdResult.mix).forEach(([fuel, { mwh }]) => {
          if (isCfeFuel(fuel)) {
            totalGridCfeMWh += mwh;
          } else {
            totalGridNonCfeMWh += mwh;
          }
        });
        const gridCfePercentForHour = totalGridCfeMWh / ((totalGridCfeMWh + totalGridNonCfeMWh) || 1);
        const gridConsumptionForHourWh = customerResult ? customerResult.consumption_wh - totalHourProgGenWh : 0;
        const gridCfeForHour = gridConsumptionForHourWh * gridCfePercentForHour;


        totalsByHour[h].consumedWh += customerResult?.consumption_wh || 0;
        totalsByHour[h].cfeWh += totalHourProgGenWh + gridCfeForHour;
      }
    }

    return Object.entries(totalsByHour).map(([hour, totals]) => {
      const cfeScore = totals.cfeWh / (totals.consumedWh || 1) * 100;
      return { hour, cfeScore };
    });

  }, [stdDeliveryResultsByHour, hourlyResultsByHour, startDateStr, allocationLengthDays]);

  const dateCategories = useMemo(() => {
    const dateCats: string[] = [];
    for (let i = 1; i < 31; i++) {
      dateCats.push(i.toString());
    }
    return dateCats;
  }, []);

  const cfeScoreChartOptions: Highcharts.Options = {
    chart: {
      type: 'heatmap',
    },
    lang: {
      noData: 'The customer has no load for the selected time range',
    },
    yAxis: {
      categories: ['00:01', '02:00', '04:00', '06:00', '08:00', '10:00', '12:00', '14:00', '16:00', '18:00', '20:00', '22:00'],
    },
    xAxis: {
      categories: dateCategories,
    },
    colorAxis: {
      dataClasses: [
        { from: 0, to: 5, color: '#5d5f66' },
        { from: 5, to: 10, color: '#868686' },
        { from: 10, to: 20, color: '#dee2e6' },
        { from: 20, to: 50, color: '#a8d9ce' },
        { from: 50, color: '#62c1ab' },
      ],
    },
    legend: {
      enabled: true,
      align: 'center',
      verticalAlign: 'bottom',
      itemDistance: 52,
      squareSymbol: false,
      symbolWidth: 32,
      symbolHeight: 12,
      symbolRadius: 2,
      valueSuffix: '%',
    },
    tooltip: {
      formatter: function () {
        return `<b>${((this.point.value || 0)).toFixed(1)}% CFE</b><br/>` +
          `At <b>${this.series.yAxis.categories[this.point.y || 0]}</b> on day <b>${this.series.xAxis.categories[this.point.x]}</b> from start date<br/>`
      }
    },
    series: [{
      type: 'heatmap',
      pointPadding: 6,
      data: hourlyCfeScoreData,
    }],
  };

  const cfeClockChartOptions: Highcharts.Options = {
    chart: {
      type: 'pie',
    },
    title: {
      text: 'Hour average',
      align: 'center',
      verticalAlign: 'middle',
      style: {
        fontSize: '14px',
        color: '#868686',
      },
    },
    tooltip: {
      formatter: function () {
        const dataPoint = clockChartHourlyAverages.find(d => d.hour === this.point.name);
        return `<b>${((dataPoint?.cfeScore || 0)).toFixed(1)}% average CFE</b><br/>` +
          `At <b>${dataPoint?.hour}:00</b><br/>`;
      },
    },
    plotOptions: {
      pie: {
        innerSize: '55%',
        borderWidth: 8,
        borderRadius: 8,
        borderColor: '#fff',
        dataLabels: { enabled: false },
        startAngle: -7.5, // ensure 00:00 is at exact top of chart - 1/48 of 360 degrees
      },
    },
    series: [{
      type: 'pie',
      name: 'hourly averages',
      keys: ['name', 'y', 'score'],
      data: clockChartHourlyAverages.map(d => ({
        name: d.hour,
        y: 1,
        color: getColorFromScore(d.cfeScore),
      })),
    }],
  };

  const maxEndDate = startAndEnd?.end ? new Date(startAndEnd.end) : undefined;
  maxEndDate && maxEndDate.setUTCDate(maxEndDate.getUTCDate() - 30);
  const loading = stdDeliveryRes.isLoading || customerRes.isLoading;
  return (
    <>
      <BasePaper titleContent="Hourly CFE Score" actions={<UtcDatePicker value={startDate} isStartDate={true} startQs="cfes" maxDate={maxEndDate} />}>
        {loading &&
          <Box pos="relative" w="100%" h={40}>
            <LoadingOverlay visible={true} />
          </Box>
        }
        {!loading && allocationLengthDays >= 30 &&
          <Group w="100%">
            <div className="hourly-cfe-score--matrix-container">
              <BaseChart overrideOptions={cfeScoreChartOptions} />
            </div>
            <div className="hourly-cfe-score--clock-container">
              <BaseChart overrideOptions={cfeClockChartOptions} />
            </div>
          </Group>
        }
        {!loading && allocationLengthDays < 30 &&
          <div>CFE scores only available for allocations 30 days or longer</div>
        }
      </BasePaper>
    </>
  );
}

export default HourlyCfeScoreChart;