import { Title } from '@mantine/core';
import { useParams } from 'react-router-dom';

import PageBreadcrumbs from 'amp/components/PageBreadcrumbs/pageBreadcrumbs';
import { getAllocationRunStartAndEnd } from 'amp/store/allocationRuns/selectors';
import AllocationResultsCustomer from 'amp/views/allocationResultsCustomer';
import { toNumericDateString } from 'shared/utils/dates';
import { useAppSelector } from 'store';
import './style.css';

const ReportsCustomerDashboard = () => {
  const { runId = '' } = useParams<{ runId: string }>();
  const startAndEnd = useAppSelector(s => getAllocationRunStartAndEnd(s, runId));

  let title = 'Report';
  if (startAndEnd?.end) {
    title = `Report ${toNumericDateString(startAndEnd.start)} - ${toNumericDateString(startAndEnd.end)}`;
  }

  return (
    <div>
      <div className="report-header--container">
        <PageBreadcrumbs />
        <Title size="24px" mt="16px" mb="16px">{title}</Title>
      </div>
      {/* TODO: probably shouldn't render a view from a view */}
      <AllocationResultsCustomer isReport />
    </div>
  );
}

export default ReportsCustomerDashboard;