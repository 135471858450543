import { HighchartsReactRefObject } from 'highcharts-react-official';
import { flatten, sum } from 'ramda';
import { useEffect, useMemo, useRef } from 'react';

import { useProgramsPage } from 'amp/store/programs/hooks';
import BaseChart from 'shared/components/Chart/baseChart';
import BasePaper from 'shared/components/Paper/basePaper';
import { IAllocationSummaryResult } from 'shared/types/allocation';

const ProgramGenerationMatch = ({summary}: {summary: IAllocationSummaryResult['summary_results']}) => {
  const programsRes = useProgramsPage({page: 1, perPage: 25});
  const totalProgramGenWh = sum(Object.values(summary.hourly_gen_and_load_by_program).map((series) => sum(series.map(({total_gen_wh}) => total_gen_wh))))

  const programGenMatchingData = useMemo(() => Object.entries(summary.hourly_gen_and_load_by_program).map(([programId, hourlyGenAndLoad]) => {
    const totalDedicatedWh = sum(hourlyGenAndLoad.map((hour) => hour.dedicated_gen_wh));
    const totalAllocatedWh = sum(hourlyGenAndLoad.map((hour) => hour.allocated_gen_wh));
    const totalGenWh = sum(hourlyGenAndLoad.map(hour => hour.total_gen_wh));

    return {
      program: programsRes.data.find(({id}) => id === programId)?.name || 'Unknown program',
      totalGenMatchedWh: totalAllocatedWh,
      totalGenDedicatedWh: totalAllocatedWh,
      totalGenWh,
      excessGenWh: totalDedicatedWh - totalAllocatedWh,
      shareOfGeneration: totalGenWh / totalProgramGenWh,
    };
  }), [programsRes.data, summary.hourly_gen_and_load_by_program, totalProgramGenWh]);

  const chartRef = useRef<HighchartsReactRefObject>(null);

  useEffect(() => {
    if (chartRef.current) {
      const container = chartRef.current.container.current;
      if (container) {
        container.style.width = "100%";
      }
    }
  }, [chartRef]);

  const seriesData = flatten(programGenMatchingData.map(datum => {
    const matched = {name: `${datum.program} generation matched`, y: (datum.totalGenMatchedWh / datum.totalGenWh) * 100 * datum.shareOfGeneration};
    const excess = {name: `${datum.program} generation excess`, y: (1.0 - (datum.totalGenMatchedWh / datum.totalGenWh)) * 100 * datum.shareOfGeneration, color: '#E9ECEF'};
    return [matched, excess];
  }));

  const totalMatched = sum(seriesData.filter(({name}) => name.includes('matched')).map(({y}) => y));
  const pieChartOptions: Highcharts.Options = {
    chart: {
      type: 'pie',
    },
    legend: {
      enabled: false,
      alignColumns: false,
      padding: 0,
      margin: 0,
    },
    title: {
      verticalAlign: 'middle',
      align: 'center',
      floating: true,
      text: `${isNaN(totalMatched) ? 'Missing Data' : totalMatched.toFixed(1)}%`,
      style: {
        color: 'var(--color-blue-3)',
        fontSize: '32px',
        fontWeight: '700',
      }
    },
    plotOptions: {
      pie: {
        innerSize: '60%',
        borderWidth: 3,
        borderRadius: 8,
        borderColor: '#fff',
        dataLabels: {
          alignTo: 'plotEdges',
          borderRadius: 4,
          shadow: true,
          backgroundColor: 'white',
          formatter: function() {
            const formattedNum = (this.y || 0).toFixed(1);
            return `${this.key}<br/><span><span style="color:${this.color}">●</span> <b>${formattedNum}</b> %</span>`;
          }
        },
        tooltip: {
          valueDecimals: 1,
          pointFormatter: function () {
            const formattedNum = this.y?.toLocaleString() || '';
            return `<span style="color:${this.color}">●</span> <b>${formattedNum}</b> %`;
          },
        }
      }
    },
    series: [{
      type: 'pie',
      data: seriesData,
    }],
    colors: ["var(--color-green-1)", "#C3FBE9", "var(--color-green-0)", "var(--color-green-3)"]
  };

  return <BasePaper className="allocation-results-overview-pie--container" titleContent={<div style={{height: 36}}>Total Program Generation Matched</div>}>
    <BaseChart overrideOptions={pieChartOptions} chartRef={chartRef}/>
  </BasePaper>
};

export default ProgramGenerationMatch;