import { Group, Menu, Select } from '@mantine/core';
import { IconChevronDown, IconDots, IconDownload } from '@tabler/icons-react';
import { indexBy, prop, sum } from 'ramda';
import { useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { useAllocationRun, useAllocationRunCustomerOutputData, useAllocationRunStdDeliveryOutputData } from 'amp/store/allocationRuns/hooks';
import { getAllocationRunStartAndEnd } from 'amp/store/allocationRuns/selectors';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import BaseChart from 'shared/components/Chart/baseChart';
import UtcDatePicker from 'shared/components/DatePicker/utcDatePicker';
import BasePaper from 'shared/components/Paper/basePaper';
import { getCurrentUser } from 'shared/store/user/selectors';
import { AssetEventResolution } from 'shared/types/assetEvents';
import { isCfeFuel } from 'shared/types/generator';
import { groupData } from 'shared/utils/chart';
import { getLastYearEnd, getLastYearStart } from 'shared/utils/dates';
import { useAppSelector } from 'store';


const ONE_MONTH_MILLIS = 1000 * 60 * 60 * 24 * 31;

const ConsumptionMatchingTimeSeries = () => {
  const { runId = '' } = useParams<{ runId: string }>();
  const [params, setParams] = useSearchParams();
  const oci = useAppSelector(getViewingOpCoId);

  useAllocationRun(runId, oci);
  const startAndEnd = useAppSelector(s => getAllocationRunStartAndEnd(s, runId));

  const defaultStart = getLastYearStart();
  const defaultEnd = getLastYearEnd();
  const startDateStr = params.get('s') || (startAndEnd?.start || defaultStart).toISOString();
  const endDateStr = params.get('e') || (startAndEnd?.end || defaultEnd).toISOString();
  const startDate = useMemo(() => new Date(startDateStr), [startDateStr]);
  const endDate = useMemo(() => new Date(endDateStr), [endDateStr]);

  let resolution = params.get('r') || '1d';
  const isHourlyDisabled = Math.abs(startDate.valueOf() - endDate.valueOf()) > ONE_MONTH_MILLIS;
  const resolutionOptions = [
    { label: `Hourly${isHourlyDisabled ? ' (1 month max)' : ''}`, value: AssetEventResolution.HOUR, disabled: isHourlyDisabled },
    { label: 'Daily', value: AssetEventResolution.DAY },
    // { label: 'Monthly', value: AssetEventResolution.MONTH },
  ];
  if (isHourlyDisabled && resolution === '1h') {
    resolution = '1d';
  }

  const customerId = params.get('c');
  const currentCustomerId = useAppSelector(getCurrentUser)?.customer_id;
  const customerRes = useAllocationRunCustomerOutputData(runId, oci || currentCustomerId, customerId);
  const customerResult = customerRes.data;
  const hourlyResults = useMemo(() => customerResult?.hourly_results || [], [customerResult]);
  const hourlyResultsByHour = indexBy(prop('datetime'), hourlyResults);
  const stdDeliveryRes = useAllocationRunStdDeliveryOutputData(runId, oci || currentCustomerId);
  const stdDeliveryResult = stdDeliveryRes.data;
  const stdDelivery = stdDeliveryResult?.hourly_results;

  // calculate the various series in a memo
  const [consumptionSeriesMwh, programGenSeriesMwh, excessGenSeriesMwh, gridCfeSeriesMWh, gridNonCfeSeriesMWh] = useMemo(() => {
    const consumptionMwh: number[][] = [];
    const programsGenerationMwh: number[][] = [];
    const excessProgramsGeneration: number[][] = [];
    const gridCfeSeries: number[][] = [];
    const gridNonCfeSeries: number[][] = [];
    if (!stdDelivery || !hourlyResults.length) {
      return [null, null, null, null, null];
    }

    stdDelivery?.forEach(stdHour => {
      const currentHour = new Date(stdHour.hour);
      const xValue = new Date(stdHour.hour).valueOf();
      if (currentHour <= endDate && currentHour >= startDate) {
        const hourlyConsumptionWh = hourlyResultsByHour[stdHour.hour]?.consumption_wh || 0;
        consumptionMwh.push([xValue, hourlyConsumptionWh / 1_000_000]);

        const subscriptionResults = hourlyResultsByHour[stdHour.hour]?.subscription_program_generation || [];
        const allocatedWh = sum(subscriptionResults.map(s => s.allocated_wh));
        const excessWh = sum(subscriptionResults.map(s => s.excess_gen_wh));
        programsGenerationMwh.push([xValue, allocatedWh / 1_000_000]);
        excessWh && excessProgramsGeneration.push([xValue, excessWh / 1_000_000]);

        if (allocatedWh < hourlyConsumptionWh) {
          const whMissing = hourlyConsumptionWh - allocatedWh;
          const totalStdMWh = sum(Object.values(stdHour.mix).map(d => d.mwh));
          if (!totalStdMWh) { // prevent divide by 0
            return;
          }

          let totalGridCfeMWh = 0;
          let totalGridNonCfeMWh = 0;
          Object.entries(stdHour.mix).forEach(([fuel, { mwh }]) => {
            const percentOfMix = mwh / totalStdMWh;
            if (isCfeFuel(fuel)) {
              totalGridCfeMWh += (whMissing * percentOfMix) / 1_000_000;
            } else {
              totalGridNonCfeMWh += (whMissing * percentOfMix) / 1_000_000;
            }
          });
          totalGridCfeMWh && gridCfeSeries.push([xValue, totalGridCfeMWh]);
          totalGridNonCfeMWh && gridNonCfeSeries.push([xValue, totalGridNonCfeMWh]);
        }
      }
    });

    const grouping = resolution === AssetEventResolution.DAY ? 'day' : 'hour';
    const groupedConsumption = groupData(consumptionMwh.map(d => ({ epoch: d[0], value: d[1] })), grouping);
    const groupedProgramGen = groupData(programsGenerationMwh.map(d => ({ epoch: d[0], value: d[1] })), grouping);
    const groupedProgramExcess = groupData(excessProgramsGeneration.map(d => ({ epoch: d[0], value: d[1] })), grouping);
    const groupedGridCfe = groupData(gridCfeSeries.map(d => ({ epoch: d[0], value: d[1] })), grouping);
    const groupedGridNonCfe = groupData(gridNonCfeSeries.map(d => ({ epoch: d[0], value: d[1] })), grouping);


    return [groupedConsumption, groupedProgramGen, groupedProgramExcess, groupedGridCfe, groupedGridNonCfe];
  }, [stdDelivery, hourlyResultsByHour, hourlyResults, startDate, endDate, resolution]);

  const onParamsChange = (params: { name: string, value: string }[]) => {
    setParams(newParams => {
      params.forEach(p => {
        newParams.set(p.name, p.value);
      });
      return newParams;
    });
  };

  const onResolutionChange = (newResolution: string | null) => {
    if (!newResolution) {
      return;
    }
    onParamsChange([{ name: 'r', value: newResolution }]);
  };

  const onExportToCsv = () => {
    const allFuels: Set<string> = new Set();
    stdDelivery?.forEach(stdHour => {
      Object.keys(stdHour.mix).forEach(key => allFuels.add(key));
    });

    // always loop over the allFuels set when adding to a row so fuels always get the same column
    const headerRow = ['Timestamp', 'Consumption Wh', 'Program Dedicated Gen Wh', 'Program Allocated Gen Wh'];
    allFuels.forEach(fuel => headerRow.push(`Grid Mix ${fuel} MWh`));
    const rows: (string | number)[][] = [headerRow];

    stdDelivery?.forEach(stdHour => {
      const subscriptionResults = hourlyResultsByHour[stdHour.hour]?.subscription_program_generation || [];
      const dedicatedWh = sum(subscriptionResults.map(s => s.dedicated_wh));
      const allocatedWh = sum(subscriptionResults.map(s => s.allocated_wh));

      const row = [
        stdHour.hour,
        hourlyResultsByHour?.[stdHour.hour]?.consumption_wh,
        dedicatedWh,
        allocatedWh,
      ];

      allFuels.forEach(fuel => row.push(stdHour.mix[fuel]?.mwh || 0));
      rows.push(row);
    });

    let csvContent = "data:text/csv;charset=utf-8," + rows.map(e => e.join(",")).join("\n");
    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  };

  const customerHasSubscription = hourlyResults.filter(hr => Object.keys(hr.subscription_program_generation).length > 0).length > 0;
  const programSeries: Highcharts.SeriesColumnOptions[] = [{
    type: 'column',
    name: 'Excess Programs Certificates',
    color: '#A9DACE',
    data: excessGenSeriesMwh || [],
  }, {
    type: 'column',
    name: 'Programs Generation',
    color: '#62C1AB',
    data: programGenSeriesMwh || [],
  }];
  const nonProgramSeries: (Highcharts.SeriesColumnOptions | Highcharts.SeriesLineOptions)[] = [{
    type: 'column',
    name: 'Grid CFE',
    color: '#3F9493',
    data: gridCfeSeriesMWh || [],
  }, {
    type: 'column',
    name: 'Grid Non-CFE',
    color: '#B6BEC5',
    data: gridNonCfeSeriesMWh || [],
  }, {
    type: 'line',
    name: 'Consumption',
    color: '#72B3FF',
    data: consumptionSeriesMwh || [],
  }];
  const series: Highcharts.SeriesOptionsType[] = customerHasSubscription ? [...programSeries, ...nonProgramSeries] : nonProgramSeries;
  const consumptionMatchingChartOptions: Highcharts.Options = {
    chart: {
      height: '300px',
    },
    legend: {
      enabled: true,
    },
    tooltip: {
      valueSuffix: ' MWh',
      valueDecimals: 1,
      xDateFormat: resolution === '1d' ? '%d %b, %Y' : '%d %b, %Y @ %H:%M'
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      }
    },
    series: series,
  };

  return (
    <BasePaper
      titleContent={<Group justify="space-between" w="100%">
        <div>Consumption Matching</div>
        <Group fw={400}>
          <Select
            value={resolution}
            data={resolutionOptions}
            onChange={onResolutionChange}
            rightSection={<IconChevronDown size={20} />}
            w="180px"
          />
          <UtcDatePicker
            value={startDate}
            isStartDate={true}
            maxDate={endDate}
          />
          -
          <UtcDatePicker
            value={endDate}
            minDate={startDate}
          />
          <Menu position='bottom-end'>
            <Menu.Target>
              <IconDots className="program-outlet--context-menu" size={16} />
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item p={0} onClick={onExportToCsv}>
                <Group p={8}>
                  <IconDownload size={12} />
                  Download as CSV
                </Group>
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Group>
      </Group>}>
      <BaseChart overrideOptions={consumptionMatchingChartOptions} />
    </BasePaper>
  );
};

export default ConsumptionMatchingTimeSeries;