import { API } from 'shared/api';
import { queries, staticDataQueries } from 'shared/api/queries';
import { IAllocationDiagnosticItem, IAllocationRun, IAllocationRunInputData, IAllocationSummaryResult, ICreateAllocationRunPayload, ICustomerAllocationSummaryResult, IProgramAllocationSummaryResult, IStandardDeliveryResult } from 'shared/types/allocation';
import { IDashboardPaginationResponse } from 'shared/types/api';


export interface IListAllocationRunsArgs {
  page: number
  perPage: number
  customerId?: string | null
  statuses?: IAllocationRun['status'][]
}

export interface IEditAllocationRunPayload {
  runId: string
  customerId?: string | null
  body: {
    status: IAllocationRun['status']
    description?: string | null
  }
}

export interface IListAllocationDiagnosticsArgs {
  customerId?: string | null
  allocationRunId: string
  page: number
  perPage: number
  levels: string[]
  families: string[]
}


export const fetchRunsPage = (qs: string) => API.get<IDashboardPaginationResponse<IAllocationRun>>(`/api/v2/allocation_runs/?${qs}`);

export const fetchAllocationRunInput = (runId: string, customerId: string) => API.get<IAllocationRunInputData>(`/${customerId}/allocation-runs/${runId}/input.json`)

const allocationRunsQueries = queries.injectEndpoints({
  endpoints: (build) => ({
    createRun: build.mutation<{ allocation_run: IAllocationRun }, { data: ICreateAllocationRunPayload, customerId?: string | null }>({
      query: ({ data, customerId }) => {
        return {
          url: `/v2/allocation_runs/?${customerId ? `customer_id=${customerId}` : ''}`,
          method: 'POST',
          body: data,
        }
      },

      invalidatesTags: [{ type: 'AllocationRun', id: 'PAGE' }],
    }),

    paginateAllocationRuns: build.query<IDashboardPaginationResponse<IAllocationRun>, IListAllocationRunsArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('page', body.page.toString());
        qs.set('per_page', body.perPage.toString());

        if (body.customerId) {
          qs.set('customer_id', body.customerId);
        }

        if (body.statuses) {
          body.statuses.forEach(status => {
            qs.append('statuses', status);
          })
        }

        return {
          url: `/v2/allocation_runs/?${qs}`,
          method: 'GET',
        }
      },

      providesTags: [{ type: 'AllocationRun', id: 'PAGE' }],
    }),

    fetchAllocationRun: build.query<{ allocation_run: IAllocationRun }, { id: string, customerId?: string | null }>({
      query: ({ id, customerId }) => `/v2/allocation_runs/${id}?${customerId ? `customer_id=${customerId}` : ''}`,
    }),

    paginateDiagnostics: build.query<IDashboardPaginationResponse<IAllocationDiagnosticItem>, IListAllocationDiagnosticsArgs>({
      query: (args) => {
        const qs = new URLSearchParams();

        if (args.customerId) {
          qs.set('customer_id', args.customerId);
        }

        qs.set('page', args.page.toString());
        qs.set('per_page', args.perPage.toString());

        args.families.forEach(family => qs.append('family', family));

        args.levels.forEach(level => qs.append('level', level));
        return `/v2/allocation_runs/${args.allocationRunId}/diagnostics?${qs}`
      },
    }),

    patchAllocationRun: build.mutation<{ allocation_run: IAllocationRun }, IEditAllocationRunPayload>({
      query: ({ runId, customerId, body }) => ({
        url: `/v2/allocation_runs/${runId}/?${customerId ? `customer_id=${customerId}` : ''}`,
        method: 'PATCH',
        body,
      }),

      invalidatesTags: (res) => res ? [{ type: 'AllocationRun', id: 'PAGE' }, { type: 'AllocationRun', id: res.allocation_run.id }] : [],
    }),
  }),
  overrideExisting: false,
});

const allocationResultsQueries = staticDataQueries.injectEndpoints({
  endpoints: (build) => ({
    fetchAllocationRunSummaryResults: build.query<IAllocationSummaryResult, { runId: string, rootCustomerId: string }>({
      query: ({ runId, rootCustomerId }) => ({
        url: `/${rootCustomerId}/allocation-runs/${runId}/summary_output.json`,
      }),

      providesTags: (res, _, args) => res ? [{ type: 'AllocationRunSummary', id: args.runId }] : [],
    }),

    fetchAllocationRunInputData: build.query<IAllocationRunInputData, { runId: string, rootCustomerId: string }>({
      query: ({ runId, rootCustomerId }) => ({
        url: `/${rootCustomerId}/allocation-runs/${runId}/input.json`,
      }),

      providesTags: (res, _, args) => res ? [{ type: 'AllocationRunInput', id: args.runId }] : [],
    }),

    fetchAllocationRunCustomerResults: build.query<ICustomerAllocationSummaryResult, { runId: string, rootCustomerId: string, subCustomerId: string }>({
      query: ({ runId, rootCustomerId, subCustomerId }) => ({
        url: `/${rootCustomerId}/allocation-runs/${runId}/customer/${subCustomerId}_output.json`,
      }),

      providesTags: (res, _, args) => res ? [{ type: 'AllocationRunCustomerResult', id: `${args.runId}_${args.subCustomerId}` }] : [],
    }),

    fetchAllocationRunStdDeliveryResults: build.query<IStandardDeliveryResult, { runId: string, rootCustomerId: string }>({
      query: ({ runId, rootCustomerId }) => ({
        url: `/${rootCustomerId}/allocation-runs/${runId}/std_delivery_output.json`,
      }),

      providesTags: (res, _, args) => res ? [{ type: 'AllocationRunStdDeliveryResult', id: args.runId }] : [],
    }),

    fetchAllocationRunProgramResult: build.query<IProgramAllocationSummaryResult, { runId: string, rootCustomerId: string, programId: string }>({
      query: ({ runId, rootCustomerId, programId }) => ({
        url: `/${rootCustomerId}/allocation-runs/${runId}/program/${programId}_output.json`,
      }),

      providesTags: (res, _, args) => res ? [{ type: 'AllocationRunProgramResult', id: `${args.runId}_${args.programId}` }] : [],
    }),
  }),
  overrideExisting: false,
});

export const {
  usePrefetch,
  usePaginateAllocationRunsQuery,
  useFetchAllocationRunQuery,
  useCreateRunMutation,
  usePaginateDiagnosticsQuery,
  usePatchAllocationRunMutation,
  endpoints,
} = allocationRunsQueries;

export const {
  useFetchAllocationRunSummaryResultsQuery,
  useFetchAllocationRunInputDataQuery,
  useFetchAllocationRunCustomerResultsQuery,
  useFetchAllocationRunStdDeliveryResultsQuery,
  useFetchAllocationRunProgramResultQuery,
} = allocationResultsQueries;