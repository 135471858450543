import { Group, Select } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { flatten } from 'ramda';
import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { getAllocationRunStartAndEnd } from 'amp/store/allocationRuns/selectors';
import { useProgramsPage } from 'amp/store/programs/hooks';
import BaseChart from 'shared/components/Chart/baseChart';
import UtcDatePicker from 'shared/components/DatePicker/utcDatePicker';
import BasePaper from 'shared/components/Paper/basePaper';
import { IAllocationSummaryResult } from 'shared/types/allocation';
import { groupData } from 'shared/utils/chart';
import { getThisYearEnd, getThisYearStart } from 'shared/utils/dates';
import { useAppSelector } from 'store';


const resolutionOptions = [
  { label: 'Hourly', value: 'hour' },
  { label: 'Daily', value: 'day' },
  { label: 'Monthly', value: 'month' },
];


const EnergyGeneration = ({ summary, runId }: { summary: IAllocationSummaryResult['summary_results'], runId: string }) => {
  // TODO: optimize this, React is warning that it's slow
  const {
    data: programs,
  } = useProgramsPage({ page: 1, perPage: 25 });
  const [params, setParams] = useSearchParams();
  const startAndEnd = useAppSelector(s => getAllocationRunStartAndEnd(s, runId));

  const startDateStr = params.get('s') || startAndEnd?.start?.toISOString() || '2023-01-01T00:00:00Z';
  const endDateStr = params.get('e') || startAndEnd?.end?.toISOString() || '2024-01-01T00:00:00Z';
  const resolution = params.get('r') || 'day';

  const earliestDt = useMemo(() => new Date(flatten(Object.values(summary.hourly_gen_and_load_by_program)).reduce((soFar, nextUp) => {
    if (!soFar) {
      return nextUp.hour;
    }
    const soFarDate = new Date(soFar);
    const nextUpDate = new Date(nextUp.hour);

    if (soFarDate < nextUpDate) {
      return soFarDate.toISOString();
    } else {
      return nextUpDate.toISOString()
    }
  }, startDateStr) || 0), [startDateStr, summary.hourly_gen_and_load_by_program]);

  const latestDt = useMemo(() => new Date(flatten(Object.values(summary.hourly_gen_and_load_by_program)).reduce((soFar, nextUp) => {
    if (!soFar) {
      return nextUp.hour;
    }
    const soFarDate = new Date(soFar);
    const nextUpDate = new Date(nextUp.hour);

    if (soFarDate > nextUpDate) {
      return soFarDate.toISOString();
    } else {
      return nextUpDate.toISOString()
    }
  }, endDateStr) || 0), [endDateStr, summary.hourly_gen_and_load_by_program]);

  const startDate = useMemo(() => startDateStr ? new Date(startDateStr || earliestDt) : getThisYearStart(), [startDateStr, earliestDt]);
  const endDate = useMemo(() => endDateStr ? new Date(endDateStr || latestDt) : getThisYearEnd(), [endDateStr, latestDt]);

  const chartOptions: Highcharts.Options = useMemo(() => {
    return {
      series: Object.entries(summary.hourly_gen_and_load_by_program)
        .map(([programId, hourlyGenAndLoad]) => {
          const data = hourlyGenAndLoad
            .map(({ total_gen_wh, hour }) => ({ epoch: new Date(hour).valueOf(), value: total_gen_wh / 1_000_000 }))
            .filter(({ epoch }) => {
              if (endDateStr && startDateStr) {
                const epochDate = new Date(epoch);
                return epochDate < new Date(endDateStr) && epochDate >= new Date(startDateStr);
              } else if (startDateStr) {
                return new Date(epoch) >= new Date(startDateStr);
              } else if (endDateStr) {
                return new Date(epoch) < new Date(endDateStr);
              } else {
                return true;
              }
            });
          return {
            type: 'column',
            stacking: 'normal',
            name: programs.find(p => p.id === programId)?.name || 'Unknown program',
            data: groupData(data, resolution as 'day' | 'hour' | 'month'),
          };
        }),
      legend: {
        enabled: true,
      },
      plotOptions: {
        column: {
          tooltip: {
            valueSuffix: ' MWh',
            valueDecimals: 1,
          },
        },
      },
      chart: {
        type: 'column',
      },
      colors: [
        "var(--color-teal-1)",
        "#A5D9FF",
        "#92A8FF",
        "var(--color-blue-1)",
        "var(--color-blue-2)",
        "var(--color-blue-0)",
      ]
    };
  }, [programs, summary.hourly_gen_and_load_by_program, resolution, endDateStr, startDateStr]);

  const onParamsChange = (params: { name: string, value: string }[]) => {
    setParams(newParams => {
      params.forEach(p => {
        newParams.set(p.name, p.value);
      });
      return newParams;
    });
  };

  const onResolutionChange = (newResolution: string | null) => {
    if (!newResolution) {
      return;
    }
    onParamsChange([{ name: 'r', value: newResolution }]);
  };

  return (
    <BasePaper className="allocation-results-overview-generation--container" titleContent="Energy Generation" actions={
      <Group ml="lg" mr="lg" fw="400">
        <Select
          value={resolution}
          data={resolutionOptions}
          onChange={onResolutionChange}
          rightSection={<IconChevronDown size={20} />}
          w="120px"
          className="customer-view-consumption-resolution--select"
        />
        <UtcDatePicker
          value={startDate}
          isStartDate={true}
          maxDate={latestDt}
          minDate={earliestDt}
        />
        -
        <UtcDatePicker
          value={endDate}
          maxDate={latestDt}
          minDate={earliestDt}
        />
      </Group>
    }>
      <BaseChart overrideOptions={chartOptions} yAxisTitle="MWh Generated" />
    </BasePaper>
  );
};



export default EnergyGeneration;