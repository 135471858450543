import { Group, Pagination, Select, Skeleton, Table, Text, Tooltip } from '@mantine/core';
import { IconChevronDown } from '@tabler/icons-react';
import { useEffect, useMemo } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';

import { usePagination } from '@mantine/hooks';
import { useAmpNav } from 'amp/hooks';
import { useUtilityAllocationRuns } from 'amp/store/allocationRuns/hooks';
import { getAllocationRunStartAndEnd } from 'amp/store/allocationRuns/selectors';
import { getViewingCustomerType, getViewingOpCoId } from 'amp/store/ui/selectors';
import { useFetchPublicUserQuery } from 'shared/api/users';
import BasePaper from 'shared/components/Paper/basePaper';
import { CustomPaginationItem } from 'shared/components/Table/baseTable';
import { useUtilityCustomers } from 'shared/store/customers/hooks';
import { getCurrentCustomer } from 'shared/store/user/selectors';
import { AllocationRunStatus, IAllocationRun } from 'shared/types/allocation';
import { CustomerType, isUtilityCustomerAccount } from 'shared/types/customer';
import { timestampToNumericDate, toNumericDateString } from 'shared/utils/dates';
import { useAppSelector } from 'store';
import './style.css';

const ReportsTableRow = ({ allocationRun }: { allocationRun: IAllocationRun }) => {
  const [params] = useSearchParams();
  const customerId = params.get('c');
  const nav = useAmpNav();

  const startAndEnd = useAppSelector(s => getAllocationRunStartAndEnd(s, allocationRun.id));
  const creationUserRes = useFetchPublicUserQuery(allocationRun.created_by);
  const creationUser = creationUserRes.data?.user;

  const onTableRowClick = () => {
    nav({
      pathname: `/dashboard/reports/${allocationRun.id}`,
      search: createSearchParams({ oci: allocationRun.customer_id, c: customerId || '' }).toString()
    });
  };

  return (
    <Table.Tr className="subscription-table--row" onClick={onTableRowClick} key={allocationRun.id}>
      <Table.Td>
        <div className="allocation-row--date-range">
          {startAndEnd?.start ? <>{toNumericDateString(startAndEnd.start)} - {toNumericDateString(startAndEnd.end)}</> : 'Unknown'}
        </div>
      </Table.Td>
      <Table.Td>
        <Tooltip label={allocationRun.description || 'no note'}>
          <Text fz={10} c="var(--color-blue-3)" className="allocations-page--note-container">
            {allocationRun.description || <em>no note</em>}
          </Text>
        </Tooltip>
      </Table.Td>
      <Table.Td>
        <Skeleton visible={creationUserRes.isLoading || creationUserRes.isFetching}>
          <Text fz={10} c="var(--color-blue-3)" ta="center">
            {creationUser?.name || 'Unknown user'}
          </Text>
        </Skeleton>
      </Table.Td>
      <Table.Td className="subscription-table-cfe--td">
        <Text fz={10} c="var(--color-blue-3)" ta="center">{timestampToNumericDate(allocationRun.created_at)}</Text>
      </Table.Td>
    </Table.Tr>
  );
}

// TODO: this page doesn't work as utility user, need a way to fetch cookies for op-cos
const ReportsView = () => {
  const [params, setParams] = useSearchParams();
  const page = isNaN(parseInt(params.get('p') || '1')) ? 1 : parseInt(params.get('p') || '1');
  const customerId = params.get('c');
  const oci = useAppSelector(getViewingOpCoId);

  const customer = useAppSelector(getCurrentCustomer)
  const viewingCustomerType = useAppSelector(getViewingCustomerType);
  const customersRes = useUtilityCustomers();
  const customers = customersRes.data || [];
  const filteredCustomers = customers.filter(c => !isUtilityCustomerAccount(c));

  // this is done in reverse alphabetical order only b/c Paul wants WalMart to show as the top option in SOCO prod...
  const sortedCustomers = filteredCustomers.sort((a, b) => a.name.localeCompare(b.name)).reverse();

  // NOTE: 15 is desired here to show 12 months of allocations plus one year-long allocation
  const allocationsRes = useUtilityAllocationRuns({ page, perPage: 13, statuses: [AllocationRunStatus.FINISHED, AllocationRunStatus.SETTLED] });
  const allocations = allocationsRes.data || [];
  const totalItems = allocationsRes.pagination?.total || 0;
  const totalPages = allocationsRes.pagination?.last || 0;
  const pagination = usePagination({ total: totalPages, page });

  // TODO: This is bad, the table paginates so we shouldn't be sorting here in the UI.
  // Paul knows this is a hack and told me to put it in, I'm going to update the backend tomorrow to get this done right - Brooke
  const sortedAllocations = allocations.sort((a, b) => new Date(a.created_at).valueOf() - new Date(b.created_at).valueOf());

  const isUtilityUser = useMemo(() => {
    const asType = viewingCustomerType as CustomerType;
    return asType === CustomerType.UTILITY || asType === CustomerType.UTILITY_OPCO;
  }, [viewingCustomerType]);

  useEffect(() => {
    // if the current user doesn't belong to a utility or OPCO, select the customer for them.
    if (!isUtilityUser) {
      customer && setParams(newParams => {
        newParams.set('c', customer.id);
        return newParams;
      });
    }
  }, [isUtilityUser, customer, setParams]);

  const onSelectCustomer = (custId: string | null) => {
    if (custId) {
      onParamChange('c', custId);
    }
  }

  const onParamChange = (paramName: string, paramValue: string | null) => {
    setParams(newParams => {
      // reset the page when customer changes
      if (paramName !== 'p') {
        newParams.delete('p');
      }

      if (paramValue === null) {
        newParams.delete(paramName);
      } else {
        newParams.set(paramName, paramValue);
      }
      return newParams;
    });
  };

  const onPageChange = (newPage: number) => {
    onParamChange('p', newPage.toString());
  };

  const mustSelectOpCo = viewingCustomerType === CustomerType.UTILITY && !oci;
  return (
    <div className="allocation-reports--page-container">
      {mustSelectOpCo &&
        <BasePaper className="allocation-reports--reports-table">
          Please select an operating company to begin
        </BasePaper>
      }
      {!mustSelectOpCo && <>
        {isUtilityUser && <div className="allocation-reports--header-controls-container">
          <Select
            value={customerId}
            data={sortedCustomers.map(c => ({ value: c.id, label: c.name }))}
            onChange={onSelectCustomer}
            rightSection={<IconChevronDown size={20} />}
            miw="200px"
            placeholder="Select a customer"
            className="customer-view-consumption-resolution--select"
          />
        </div>}
        {!customerId &&
          <BasePaper className="allocation-reports--reports-table">
            Select a customer to see their reports
          </BasePaper>
        }
        {customerId &&
          <BasePaper titleContent="Reports" className="allocation-reports--reports-table">
            <Table>
              <Table.Thead>
                <Table.Tr>
                  <Table.Th>
                    <Text size="10px" c="var(--color-blue-2)" fw="600">Report Range</Text>
                  </Table.Th>
                  <Table.Th>
                    <Text ta="center" size="10px" c="var(--color-blue-2)" fw="600">Note</Text>
                  </Table.Th>
                  <Table.Th>
                    <Text ta="center" size="10px" c="var(--color-blue-2)" fw="600">Created by</Text>
                  </Table.Th>
                  <Table.Th className="subscription-table-cfe--td">
                    <Text size="10px" ta="center" c="var(--color-blue-2)" fw="600">Created at</Text>
                  </Table.Th>
                </Table.Tr>
              </Table.Thead>
              <Table.Tbody>
                {sortedAllocations.map(row => <ReportsTableRow allocationRun={row} />)}
              </Table.Tbody>
            </Table>
            <div className="base-table--footer-container">
              <Text pos="absolute" left={0} c="var(--color-grey-4)" fz="12px">{totalItems.toLocaleString()} Total rows</Text>
              <Pagination.Root
                className="base-table--pagination-container"
                color="rgb(var(--singularity-green-rgb))"
                total={allocations.length}
                value={page}
                onChange={onPageChange}
                ta="center"
              >
                <Group gap={5}>
                  {totalPages !== 0 && <Pagination.Previous />}
                  {pagination.range.map((page, index) => <CustomPaginationItem key={`${page}${index}`} onClick={onPageChange} active={pagination.active === page} page={page} index={index} onMouseEnter={() => { }} />)}
                  {totalPages !== 0 && <Pagination.Next />}
                </Group>
              </Pagination.Root>
            </div>
          </BasePaper>
        }
      </>}
    </div>
  );
}

export default ReportsView;