import { Button, Group, Loader, Pagination, Skeleton, Table, Text, TextInput, Tooltip } from '@mantine/core';
import { usePagination } from '@mantine/hooks';
import { IconArchive, IconCheck, IconClockPause, IconPencil, IconPlayerPlay, IconSitemap, IconUsers, IconX } from '@tabler/icons-react';
import { KeyboardEvent, MouseEvent, useMemo, useState } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';

import { usePatchAllocationRunMutation } from 'amp/api/allocationRuns';
import { AmpLink } from 'amp/components/Link';
import { useAmpNav } from 'amp/hooks';
import { useAllocationRunInputData, useAllocationRunSummaryOutputData, useUtilityAllocationRuns } from 'amp/store/allocationRuns/hooks';
import { getAllocationRunStartAndEnd } from 'amp/store/allocationRuns/selectors';
import { getViewingCustomerType, getViewingOpCoId } from 'amp/store/ui/selectors';
import { usePaginateTeammatesQuery } from 'shared/api/users';
import BasePaper from 'shared/components/Paper/basePaper';
import RoleRequired from 'shared/components/RoleRequired/roleRequired';
import { CustomPaginationItem } from 'shared/components/Table/baseTable';
import { getCustomerById } from 'shared/store/customers/selectors';
import { AllocationRunStatus, IAllocationRun } from 'shared/types/allocation';
import { CustomerType } from 'shared/types/customer';
import { IUser, UserRole } from 'shared/types/user';
import { timestampToNumericDate, toNumericDateString } from 'shared/utils/dates';
import { numberToSiUnits } from 'shared/utils/strings';
import { useAppSelector } from 'store';
import './style.css';


const getIconForStatus = (status: IAllocationRun['status']) => {
  switch (status) {
    case 'IN_PROGRESS':
      return <div className="allocation-row-status--container"><Loader color="var(--color-grey-4)" size={12} /></div>;
    case 'NOT_STARTED':
    case 'PAUSED':
      return <div className="allocation-row-status--container"><IconClockPause size={12} /></div>;
    case 'ERROR':
      return <div className="allocation-row-status--container bad"><IconX color="var(--color-se-red-1)" size={12} /></div>;
    case 'FINISHED':
      return <div className="allocation-row-status--container good"><IconCheck size={12} color="var(--color-blue-1)" /></div>;
    default:
      // TODO: more statuses
      return <div className="allocation-row-status--container"><IconCheck size={12} /></div>;
  }
};

const AllocationsTableRow = ({ allocationRun, displayType }: { allocationRun: IAllocationRun, displayType: 'for_opco' | 'for_utility' }) => {
  const nav = useAmpNav();
  // TODO: use a hook for this, paginate more teammates if necessary
  const teamRes = usePaginateTeammatesQuery({ page: 1, perPage: 50 });
  const startAndEnd = useAppSelector(s => getAllocationRunStartAndEnd(s, allocationRun.id));
  const [editingNote, setEditingNote] = useState(false);
  const [editedNote, setEditedNote] = useState(allocationRun.description || '');
  const [patch, patchRes] = usePatchAllocationRunMutation();
  const oci = useAppSelector(getViewingOpCoId);

  const inputDataRes = useAllocationRunInputData(allocationRun.id, allocationRun.customer_id, displayType === 'for_utility');
  const inputDataLoading = inputDataRes.isFetching;

  const outputDataRes = useAllocationRunSummaryOutputData(allocationRun.id, allocationRun.customer_id, displayType === 'for_utility');
  const outputDataLoading = (!outputDataRes.data || outputDataRes.isLoading) && displayType === 'for_opco';

  const customerOwner = useAppSelector(s => getCustomerById(s, allocationRun.customer_id));

  const usersById = useMemo(() => {
    const byId: Record<string, IUser> = {}
    teamRes.data?.data.forEach(user => byId[user.id] = user);
    return byId
  }, [teamRes]);

  const customersById = useMemo(() => {
    const byId: Record<string, string> = {};
    inputDataRes.data?.customer_subscriptions.forEach(sub => byId[sub.customer_id] = sub.customer_id);
    return byId;
  }, [inputDataRes.data]);

  const totalEnergyAllocatedMWh = useMemo(() => {
    let totalEnergyMWh = 0;
    Object.values(outputDataRes.data?.summary_results.by_subscription_id || {}).forEach(subscriptionSummary => {
      totalEnergyMWh += subscriptionSummary.allocated_generation_mwh;
    });
    return totalEnergyMWh;
  }, [outputDataRes.data]);

  const onTableRowClick = () => {
    nav({ pathname: `/dashboard/allocation/${allocationRun.id}`, search: createSearchParams({ oci: allocationRun.customer_id }).toString() });
  };

  const numberPrograms = inputDataRes.data?.programs.length || 0;
  const numberSubscriptions = inputDataRes.data?.customer_subscriptions.length || 0;
  const numberCustomers = Object.values(customersById).length;

  const icon = getIconForStatus(allocationRun.status);

  const onStopEditing = () => {
    setEditingNote(false);
    setEditedNote(allocationRun.description || '');
  };

  const onSubmitEdit = () => {
    patch({ runId: allocationRun.id, customerId: oci, body: { status: allocationRun.status, description: editedNote } })
      .then(() => {
        setEditingNote(false);
      })
      // TODO: alert the user or Sentry
      .catch(console.warn)
  }

  const onClickEdit = (e: MouseEvent) => {
    e.stopPropagation();
    setEditingNote(true);
  }

  const onNoteKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      onSubmitEdit();
    }
  }

  const onClickArchive = (e: MouseEvent) => {
    e.stopPropagation();
    patch({ runId: allocationRun.id, customerId: oci, body: { status: AllocationRunStatus.ARCHIVED } })
      // TODO: alert the user or Sentry
      .catch(console.warn)
  };

  const formattedTotalMatched = numberToSiUnits(totalEnergyAllocatedMWh * 1_000_000);
  return (
    <Table.Tr className="subscription-table--row" onClick={onTableRowClick}>
      <Table.Td>
        <div className="allocation-row--date-range">
          {startAndEnd?.start ? <>{icon}  {toNumericDateString(startAndEnd.start)} - {toNumericDateString(startAndEnd.end)}</> : 'Unknown'}
        </div>
      </Table.Td>
      <Table.Td onClick={(e: MouseEvent) => editingNote ? e.stopPropagation() : e}>
        {editingNote ?
          <div className="allocation-row-edit-note--container">
            <TextInput size="xs" p="4px 8px" fz="10" value={editedNote} onChange={(e) => setEditedNote(e.target.value)} onKeyDown={onNoteKeyDown} />
            <Button size="xs" variant="outline" onClick={onSubmitEdit} loading={patchRes.isLoading} mr="8px" color="var(--color-teal-6)"><IconCheck size={12} /></Button>
            <Button size="xs" variant="outline" onClick={onStopEditing} disabled={patchRes.isLoading} color="red"><IconX size={12} /></Button>
          </div>
          : <Group gap={4} justify="space-between">
            <Tooltip label={allocationRun.description || 'no note'}>
              <Text fz={10} c="var(--color-blue-3)" className="allocations-page--note-container">
                {allocationRun.description || <em>no note</em>}
              </Text>
            </Tooltip>
            <Button variant="subtle" size="xs" color="grey" p="4px 8px" ml="8px" onClick={onClickEdit}>
              <IconPencil size={12} />
            </Button>
          </Group>
        }
      </Table.Td>
      <Table.Td>
        <Text fz={10} c="var(--color-blue-3)" ta="center">{usersById[allocationRun.created_by]?.name || 'Unknown user'}</Text>
      </Table.Td>
      <Table.Td className="subscription-table-cfe--td">
        <Text fz={10} c="var(--color-blue-3)" ta="center">{timestampToNumericDate(allocationRun.created_at)}</Text>
      </Table.Td>
      {displayType === 'for_opco' &&
        <>
          <Table.Td>
            <Skeleton visible={inputDataLoading}>
              <Group justify="center" gap="4px">
                <IconSitemap width="12px" height="12px" color="var(--color-green-2)" />
                <Text fz={10} c="var(--color-blue-3)" ta="center">{numberPrograms}</Text>
              </Group>
            </Skeleton>
          </Table.Td>
          <Table.Td>
            <Skeleton visible={inputDataLoading}>
              <Group justify="center" gap="4px">
                <IconUsers width="12px" height="12px" color="var(--color-blue-2)" />
                <Text fz={10} c="var(--color-blue-3)" ta="center">{numberCustomers}</Text>
              </Group>
            </Skeleton>
          </Table.Td>
          <Table.Td>
            <Skeleton visible={inputDataLoading}>
              <Group justify="center" gap="4px">
                <IconPlayerPlay width="12px" height="12px" color="var(--color-blue-1)" />
                <Text fz={10} c="var(--color-blue-3)" ta="center">{numberSubscriptions}</Text>
              </Group>
            </Skeleton>
          </Table.Td>
          <Table.Td className="subscription-table-cfe--td">
            <Skeleton visible={outputDataLoading}>
              <Text ta="center" fz={10} fw={700} c="var(--color-blue-3)">
                {formattedTotalMatched.slice(0, -1)} {formattedTotalMatched.slice(-1)}Wh
              </Text>
            </Skeleton>
          </Table.Td>
        </>}
      {displayType === 'for_utility' &&
        <Table.Td>
          <Text ta="center" fz={10} c="var(--color-blue-3)">
            {customerOwner?.name || <em>Unknown</em>}
          </Text>
        </Table.Td>}
      <RoleRequired role={UserRole.ADMIN}>
        <Table.Td onClick={onClickArchive}>
          <Tooltip label="Archive">
            <IconArchive size={16} color="var(--color-neutral-10)" />
          </Tooltip>
        </Table.Td>
      </RoleRequired>
    </Table.Tr>
  );
}

const AllocationsView = () => {
  const [params, setSearchParams] = useSearchParams();
  const viewingCustomerType = useAppSelector(getViewingCustomerType);

  // TODO: make this logic a util function or hook
  const page = isNaN(parseInt(params.get('p') || '1')) ? 1 : parseInt(params.get('p') || '1');
  const perPage = isNaN(parseInt(params.get('ps') || '10')) ? 10 : parseInt(params.get('ps') || '10');
  const statuses = [
    AllocationRunStatus.ERROR,
    AllocationRunStatus.NOT_STARTED,
    AllocationRunStatus.IN_PROGRESS,
    AllocationRunStatus.FINISHED,
    AllocationRunStatus.PAUSED,
    AllocationRunStatus.SETTLED,
  ]
  const allocationRes = useUtilityAllocationRuns({ page, perPage, statuses });
  const totalItems = allocationRes.pagination?.total || 0;
  const totalPages = allocationRes.pagination?.last || 0;
  const pagination = usePagination({ total: totalPages, page });

  const onParamChange = (paramName: string, paramValue: string | null) => {
    setSearchParams(newParams => {
      // reset the page when search params change
      if (paramName !== 'p') {
        newParams.delete('p');
      }

      if (paramValue === null) {
        newParams.delete(paramName);
      } else {
        newParams.set(paramName, paramValue);
      }
      return newParams;
    });
  };

  const onPageChange = (newPage: number) => {
    onParamChange('p', newPage.toString());
  };

  const rows = allocationRes.data || [];
  return (
    <>
      <Group justify="space-between" p="32px" pb="24px">
        <div className="allocations-page--allocations-title">
          Allocation Management Console
        </div>
        <RoleRequired role={UserRole.ADMIN}>
          <AmpLink to="/dashboard/allocation/create">
            <Button size="sm" className="allocations-page--create-button">
              Create Allocation
            </Button>
          </AmpLink>
        </RoleRequired>
      </Group>
      <div className="allocations-page--scroll-area">
        <BasePaper titleContent="History">
          <Table>
            <Table.Thead>
              <Table.Tr>
                <Table.Th>
                  <Text size="10px" c="var(--color-blue-2)" fw="600">Allocation</Text>
                </Table.Th>
                <Table.Th>
                  <Text ta="center" size="10px" c="var(--color-blue-2)" fw="600">Note</Text>
                </Table.Th>
                <Table.Th>
                  <Text ta="center" size="10px" c="var(--color-blue-2)" fw="600">Created by</Text>
                </Table.Th>
                <Table.Th className="subscription-table-cfe--td">
                  <Text size="10px" ta="center" c="var(--color-blue-2)" fw="600">Created at</Text>
                </Table.Th>
                {viewingCustomerType === CustomerType.UTILITY_OPCO ?
                  <>
                    <Table.Th>
                      <Text size="10px" ta="center" c="var(--color-blue-2)" fw="600">Programs</Text>
                    </Table.Th>
                    <Table.Th>
                      <Text size="10px" ta="center" c="var(--color-blue-2)" fw="600">Customers</Text>
                    </Table.Th>
                    <Table.Th>
                      <Text size="10px" ta="center" c="var(--color-blue-2)" fw="600">Subscriptions</Text>
                    </Table.Th>
                    <Table.Th className="subscription-table-cfe--td">
                      <Text size="10px" ta="center" c="var(--color-blue-2)" fw="600">Total Energy Allocated</Text>
                    </Table.Th>
                    <Table.Th />
                  </> :
                  <Table.Th className="subscription-table-cfe--td">
                    <Text size="10px" ta="center" c="var(--color-blue-2)" fw="600">Operating Company</Text>
                  </Table.Th>
                }
              </Table.Tr>
            </Table.Thead>
            <Table.Tbody>
              {rows.map(row => <AllocationsTableRow key={row.id} allocationRun={row} displayType={viewingCustomerType === CustomerType.UTILITY_OPCO ? 'for_opco' : 'for_utility'} />)}
            </Table.Tbody>
          </Table>
          <div className="base-table--footer-container">
            <Text pos="absolute" left={0} c="var(--color-grey-4)" fz="12px">{totalItems.toLocaleString()} Total rows</Text>
            <Pagination.Root
              className="base-table--pagination-container"
              color="rgb(var(--singularity-green-rgb))"
              total={rows.length}
              value={page}
              onChange={onPageChange}
              ta="center"
            >
              <Group gap={5}>
                {totalPages !== 0 && <Pagination.Previous />}
                {pagination.range.map((page, index) => <CustomPaginationItem key={`${page}${index}`} onClick={onPageChange} active={pagination.active === page} page={page} index={index} onMouseEnter={() => { }} />)}
                {totalPages !== 0 && <Pagination.Next />}
              </Group>
            </Pagination.Root>
          </div>
        </BasePaper>
      </div>
    </>
  );
};

export default AllocationsView;