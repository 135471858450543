let ResourcesConstants;

if (process.env.REACT_APP_ENV === 'prod') {
  ResourcesConstants = {
    API_URL: 'https://corporate-carbon.api.singularity.energy',
    LOGIN_URL: 'https://login.singularity.energy',
    WS_URL: 'wss://live.api.singularity.energy',
    SENTRY_DSN: 'https://497a01ece6851fcd80dfb65f848a78a6@o256130.ingest.us.sentry.io/4506943217860608',
  };
} else if (process.env.REACT_APP_ENV === 'qa') {
  ResourcesConstants = {
    API_URL: 'https://corporate-carbon.api.qa-singularity.energy',
    LOGIN_URL: 'https://login.qa-singularity.energy',
    WS_URL: 'wss://live.api.qa-singularity.energy',
    SENTRY_DSN: 'https://497a01ece6851fcd80dfb65f848a78a6@o256130.ingest.us.sentry.io/4506943217860608',
  };
} else {
  ResourcesConstants = {
    API_URL: 'https://corporate-carbon.api.qa-singularity.energy',
    LOGIN_URL: 'https://login.qa-singularity.energy',
    WS_URL: 'wss://live.api.qa-singularity.energy',
    SENTRY_DSN: '...',
  };
}

export const DEBUG = process.env.REACT_APP_ENV !== 'prod';
export const LOCAL = DEBUG && process.env.REACT_APP_ENV !== 'qa';
export const ENV_NAME = process.env.REACT_APP_ENV || 'local' as string;
export const MAPBOXGL_ACCESS_TOKEN = 'pk.eyJ1Ijoic2luZ3VsYXJpdHktZW5lcmd5IiwiYSI6ImNsM2FmMjRybDAwdzUzZ3BrNnZwaTYwZjIifQ.WOLczvbtOOu7y1V8g08AGA';
export const API_URL = ResourcesConstants.API_URL;
export const SENTRY_DSN = ResourcesConstants.SENTRY_DSN;
export const LOGIN_URL = ResourcesConstants.LOGIN_URL;
export const WS_URL = ResourcesConstants.WS_URL;