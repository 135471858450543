import { API } from "shared/api/index";
import { userAuthQueries } from "shared/api/queries";
import { IUserAuthPagination, IUserAuthPaginationResponse } from "shared/types/api";
import { ICustomer, displayValuesToRequestData } from "shared/types/customer";


export const fetchCustomersPage = (customerId: string, qs: string) => API.get<IUserAuthPaginationResponse<ICustomer>>(`/u/api/v1/customers/me/${customerId}/paginate?${qs}`);


export interface IListCustomersArgs {
  page: number
  perPage: number
  parentCustomerId?: string | null,
  statuses?: string[]
}

const customerPage: {type: 'Customer', id: string} = {type: 'Customer', id: 'PAGE'}

const customersQueries = userAuthQueries.injectEndpoints({
  endpoints: (build) => ({
    paginateCustomers: build.query<{data: ICustomer[], meta: {pagination: IUserAuthPagination}}, IListCustomersArgs>({
      query: (body) => {
        const qs = new URLSearchParams();
        qs.set('offset', ((body.page - 1) * body.perPage).toString());
        qs.set('limit', body.perPage.toString());

        body.statuses?.forEach(status => {
          qs.append('statuses', status)
        })

        return {
          url: `v1/customers/me/${body.parentCustomerId}/paginate?${qs}`,
          method: 'GET',
        }
      },

      providesTags: (res) => res ? [customerPage, ...res.data.map(cust => ({type: 'Customer' as const, id: cust.id}))] : [],
    }),

    fetchCustomer: build.query<{customer: ICustomer}, string>({
      query: (id) => `v1/customers/me/${id}`,
      providesTags: (res) => res ? [{type: 'Customer', id: res.customer.id}] : [],
    }),

    // TODO: stricter typing
    patchCustomer: build.mutation<{customer: ICustomer}, {id: string, body: ReturnType<typeof displayValuesToRequestData>}>({
      query: ({id, body}) => ({
        url: `v1/customers/${id}`,
        body: {...body, attributes: []},
        method: 'PATCH'
      }),
      invalidatesTags: (res) => res ? [{type: 'Customer', id: res.customer.id}] : [],
    }),

  }),
  overrideExisting: false,
});


export const {
  usePrefetch,
  usePaginateCustomersQuery,
  useFetchCustomerQuery,
  usePatchCustomerMutation,
} = customersQueries;