import { Divider, Group, Skeleton, Stack } from "@mantine/core";
import CertificatesModal from "amp/components/CertificatesModal";

import { AmpLink } from "amp/components/Link";
import { useGenerator } from "amp/store/generators/hooks";
import { sum } from "ramda";
import { useState } from "react";
import BasePaper from "shared/components/Paper/basePaper";
import { hasCertsInCustomerAllocation } from "shared/store/user/selectors";
import { fuelDisplayName, isCfeFuel } from "shared/types/generator";
import { useAppSelector } from "store";


const GeneratorRow = ({ genId, generationWh, onClick }: { genId: string, generationWh: number, onClick: () => void }) => {
  const generatorRes = useGenerator(genId);
  const hasCerts = useAppSelector(hasCertsInCustomerAllocation);

  const numberCerts = Math.floor(generationWh / 1_000_000);
  return (
    <Group justify="space-between" c="var(--color-grey-4)">
      <Group w="55%" gap={4}>
        Certificates from
        <Skeleton visible={generatorRes.isLoading} w="60%">
          <AmpLink to={`/dashboard/inventory/sources/${genId}`}>
            <div className="allocation-results-customer--generator-link">{generatorRes.data?.name || 'Unknown Generator'}</div>
          </AmpLink>
        </Skeleton>
      </Group>
      <div className={hasCerts ? "allocation-results-customer--generator-link" : ""} onClick={hasCerts ? onClick : undefined}>{numberCerts.toLocaleString()} Certificates</div>
    </Group>
  );
};


interface ICleanEnergyCertsAssignedProps {
  currentCustomerHasSubscription: boolean,
  totalProgramEnergyWh: number | null,
  totalGridCfeWh: number | null,
  hourlyGenerationMatchedByGeneratorIdWh: Record<string, Record<string, number>>,
  generationByFuelMwh: {
    grid: Record<string, number>;
    program: Record<string, number>;
  } | null,
}


const CleanEnergyCertsAssigned = ({
  currentCustomerHasSubscription,
  totalProgramEnergyWh,
  hourlyGenerationMatchedByGeneratorIdWh,
  totalGridCfeWh,
  generationByFuelMwh,
}: ICleanEnergyCertsAssignedProps) => {

  const [selectedGenForCerts, setSelectedGenForCerts] = useState('');

  const isModalOpen = !!selectedGenForCerts;

  return (
    <BasePaper titleContent="Clean Energy Certificates Assigned">
      <CertificatesModal isOpen={isModalOpen} onClose={() => setSelectedGenForCerts('')} generatorId={selectedGenForCerts} certsByHour={hourlyGenerationMatchedByGeneratorIdWh[selectedGenForCerts] || {}} />
      <Stack gap={16} fz={10}>
        {currentCustomerHasSubscription && <>
          <Stack gap={12}>
            <Group justify="space-between">
              <div>Programs CFE Certificates</div>
              <div>{Math.floor((totalProgramEnergyWh || 0) / 1_000_000).toLocaleString()} Certificates</div>
            </Group>
            {Object.entries(hourlyGenerationMatchedByGeneratorIdWh).map(([gid, whByHour]) => {
              const genWh = sum(Object.values(whByHour));

              return (
                <GeneratorRow key={gid} genId={gid} generationWh={genWh} onClick={() => setSelectedGenForCerts(gid)} />
              );
            })}
          </Stack>
          <Divider />
        </>
        }
        <Stack gap={12}>
          <Group justify="space-between">
            <div>Grid CFE Certificates</div>
            <div>{totalGridCfeWh ? Math.floor(totalGridCfeWh / 1_000_000).toLocaleString() : 0} Certificates</div>
          </Group>
          {Object.entries(generationByFuelMwh?.grid || {}).filter(([fuel,]) => isCfeFuel(fuel)).map(([fuel, MWh]) => {
            return <Group justify="space-between" c="var(--color-grey-4)" key={fuel}>
              <div>Certificates from {fuelDisplayName(fuel)} resources</div>
              <div>{Math.floor(MWh).toLocaleString()} certificates</div>
            </Group>
          })}
        </Stack>
      </Stack>
    </BasePaper>

  )
};

export default CleanEnergyCertsAssigned;