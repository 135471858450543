import { Box, Center, Group, LoadingOverlay, Pill, Skeleton, Stack, Text, Title, Tooltip } from '@mantine/core';
import { sum } from 'ramda';

import { useFetchAggregatedEventsQuery } from 'amp/api/assetEvents';
import { useFetchForecastForCustomerQuery } from 'amp/api/assetForecasts';
import { AmpLink } from 'amp/components/Link';
import { useAmpNav } from 'amp/hooks';
import { useSubscriptionsPage } from 'amp/store/programs/hooks';
import { getViewingOpCoId } from 'amp/store/ui/selectors';
import BasePaper from 'shared/components/Paper/basePaper';
import { useCustomersPage, useUtilityCustomers } from 'shared/store/customers/hooks';
import { AggregationTypes, ITotalLoadEventData } from 'shared/types/aggregatedEvents';
import { ICustomer, getCustomerLogoPath, isUtilityCustomer, isUtilityCustomerAccount } from 'shared/types/customer';
import { getThisYearEnd, getThisYearStart, isDateWithinDateRange } from 'shared/utils/dates';
import { numberToSiUnits } from 'shared/utils/strings';
import { useAppSelector } from 'store';
import './style.css';


const CustomerCard = ({ customer }: { customer: ICustomer }) => {
  const customerId = customer.id;
  const nav = useAmpNav();
  const subAccountsRes = useCustomersPage({ page: 1, perPage: 1, parentCustomerId: customerId }); // used as a count
  const numSubAccounts = subAccountsRes.pagination?.total_items || 0;
  const oci = useAppSelector(getViewingOpCoId);

  // This should cover the scope of the Southern pilot
  const subscriptionsRes = useSubscriptionsPage({ page: 1, perPage: 50, childCustomerId: customerId });
  const subscriptions = subscriptionsRes.data || [];

  const loadRes = useFetchAggregatedEventsQuery({
    startDate: getThisYearStart().toISOString(), endDate: getThisYearEnd().toISOString(), resolution: '1d', aggregationType: AggregationTypes.TOTAL_LOAD, customerId,
  }, { skip: !isUtilityCustomer(customer) });
  const loadData = loadRes.data?.data || [];
  const totalLoad = sum(loadData.map(d => (d.data as ITotalLoadEventData).sum_consumed_kwh * 1000));
  const maxEpochForLoad = Math.max(...(loadData.map(d => new Date(d.start_date).valueOf()) || []));

  const loadForecastRes = useFetchForecastForCustomerQuery({ customerId: oci, forecastCustomerId: customerId, year: getThisYearEnd().getFullYear() }, { skip: !isUtilityCustomer(customer) });
  const forecastYTD = loadForecastRes.data?.event_forecast_batch.data?.results || [];
  const totalForecastYTD = sum(forecastYTD.filter(({ start_date }) => new Date(start_date).valueOf() <= maxEpochForLoad).map(d => d.y_axis_value_wh));

  const now = new Date();
  // TODO: this logic is copied from the list programs page, it should be shared
  const activeSubscriptions = subscriptions?.filter(s => {
    if (!s.data.configuration.subscription_start || !s.data.configuration.subscription_end) {
      return true;
    } else if (!s.data.configuration.subscription_end) {
      // start exists but end doesn't - always active after start
      const start = new Date(s.data.configuration.subscription_start);
      return isDateWithinDateRange(now, start, new Date('2099-01-01T00:00:00Z'));
    } else if (!s.data.configuration.subscription_start) {
      // end exists but start doesn't - always active before end
      const end = new Date(s.data.configuration.subscription_end);
      return isDateWithinDateRange(now, new Date('1990-01-01T00:00:00Z'), end);
    } else {
      // both are present
      const start = new Date(s.data.configuration.subscription_start);
      const end = new Date(s.data.configuration.subscription_end);
      return isDateWithinDateRange(now, start, end);
    }
  });

  const formattedTotalLoad = numberToSiUnits(totalLoad);
  const formattedTotalForecast = numberToSiUnits(totalForecastYTD);
  const percentVsForecasted = totalForecastYTD ? totalLoad / totalForecastYTD : 0;

  return (
    // TODO: perhaps make BasePaper take an explicit link and do some logic there to reduce this
    <BasePaper onClick={() => nav(`/dashboard/customers/${customerId}`)} style={{ cursor: 'pointer' }} >
      <Group justify="space-between">
        <Tooltip label={customer.name} position="top-start"  transitionProps={{ transition: 'fade', duration: 200 }}>
          <Stack w="25%">
            <AmpLink to={`/dashboard/customers/${customerId}`} onClick={(e) => e.stopPropagation()}>
              <img className="allocation-results--logo" src={getCustomerLogoPath(customer)} height={36} alt={`${customer.name} logo`} />
              <Text mt="8px" fz="12px" c="var(--color-blue-1)">Customer ID: {customerId}</Text>
            </AmpLink>
          </Stack>
        </Tooltip>
        <Stack>
          <Skeleton visible={subAccountsRes.isLoading}>
            <Center>
              <Title fw="700" fz="32px" c="var(--color-blue-3)">{numSubAccounts}</Title>
            </Center>
            <Text c="var(--color-grey-4)" fz="12px">Sub-accounts</Text>
          </Skeleton>
        </Stack>
        <Stack>
          <Skeleton visible={subscriptionsRes.isLoading}>
            <Center>
              <Title fw="700" fz="32px" c="var(--color-blue-3)">{activeSubscriptions.length}</Title>
            </Center>
            <Center>
              <Text c="var(--color-grey-4)" fz="12px">Active Subscriptions</Text>
            </Center>
          </Skeleton>
        </Stack>

        <Stack>
          <Skeleton visible={subscriptionsRes.isLoading}>
            <Center>
              <Group gap="xs" align="baseline">
                <Title c="var(--color-blue-1)" size="32px">{formattedTotalForecast.slice(0, -1)}</Title>
                <Text c="var(--color-grey-4)" size="20px">{formattedTotalForecast.slice(-1)}Wh</Text>
              </Group>
            </Center>
            <Center>
              <Text c="var(--color-grey-4)" fz="12px">YTD Consumption Projection</Text>
            </Center>
          </Skeleton>
        </Stack>
        <Stack>
          <Skeleton visible={loadRes.isLoading}>
            <Center>
              <Group gap="xs" align="baseline">
                <Title fw="700" fz="32px" c="var(--color-blue-3)">{formattedTotalLoad.slice(0, -1)}</Title>
                <Text c="var(--color-grey-4)" size="lg">{formattedTotalLoad.slice(-1)}Wh</Text>
              </Group>
            </Center>
            <Center>
              <Group>
                <Pill fz="12px" radius="sm" size="xs" c={percentVsForecasted <= 1 ? "var(--color-teal-9)" : "var(--color-se-red-1)"} bg={percentVsForecasted <= 1 ? "var(--color-teal-0)" : "var(--color-red-1)"}>
                  {`${percentVsForecasted > 1 ? '+' : ''} ${((percentVsForecasted - 1) * 100).toFixed(1)} %`}
                </Pill>
                <Text c="var(--color-grey-4)" fz="12px">YTD Consumption Actual</Text>
              </Group>
            </Center>
          </Skeleton>
        </Stack>
      </Group>
    </BasePaper>
  );
}

const CustomersListView = () => {
  const res = useUtilityCustomers();
  const customers = res.data || [];
  const filteredCustomers = customers.filter(c => !isUtilityCustomerAccount(c));

  return (
    <div className="customers-page--container">
      <div className="customers-page--title">
        Customers
      </div>

      <div className="customers-page--scroll-area">
        {res.isError && <div>error</div>}
        {res.isLoading &&
          <Box pos="relative" w="100%" h="100%">
            <LoadingOverlay visible={true} />
          </Box>
        }
        {!res.isLoading &&
          <Stack gap="lg">
            {filteredCustomers.length === 0 && <BasePaper>
              No customers found
            </BasePaper>}
            {filteredCustomers.map(cust => <CustomerCard customer={cust} />)}
          </Stack>
        }
      </div>
    </div>
  );
};

export default CustomersListView;