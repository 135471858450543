import { Title } from "@mantine/core";
import { useProgramsPage } from "amp/store/programs/hooks";
import BasePaper from "shared/components/Paper/basePaper";
import BaseTable from "shared/components/Table/baseTable";
import { useCustomersPage } from "shared/store/customers/hooks";
import { IAllocationSummaryResult } from "shared/types/allocation";
import { timestampToNumericDate } from "shared/utils/dates";
import { hrFloat } from "shared/utils/math";


const tableColumns = [
  {
    key: 'customer',
    displayValue: 'Customer',
  },
  {
    key: 'program',
    displayValue: 'Program'
  },
  {
    key: 'duration',
    displayValue: 'Duration of contract',
  },
  {
    key: 'programGeneration',
    displayValue: 'Program Generation',
  },
  {
    key: 'commitment',
    displayValue: 'Commitment',
  },
  {
    key: 'generationMatched',
    displayValue: 'Generation Matched',
  },
  {
    key: 'consumption',
    displayValue: 'Subscriber Consumption',
  },
  {
    key: 'loadMatchedPct',
    displayValue: '% Load Matched',
  },
]


const AllocationDetails = ({subscriptionsSummary}: {subscriptionsSummary: IAllocationSummaryResult['summary_results']['by_subscription_id']}) => {
  const customersRes = useCustomersPage({page: 1, perPage: 20});
  const programsRes = useProgramsPage({page: 1, perPage: 20});
  const rows = Object.entries(subscriptionsSummary).map(([subId, summary]) => {
    const loadMatchedPct = `${Math.min(100, Math.round(summary.allocated_generation_mwh / summary.customer_consumption_mwh * 100)).toLocaleString()}%`
    return {
      id: subId,
      customer: customersRes.data?.find(cust => cust.id === summary.subscribed_customer_id)?.name || <em>unknown customer</em>,
      program: programsRes.data?.find(prog => prog.id === summary.program_id)?.name || <em>unknown program</em>,
      duration: `${timestampToNumericDate(summary.start)} - ${timestampToNumericDate(summary.end)}`,
      programGeneration: `${Math.floor(summary.program_generation_mwh).toLocaleString()} MWh`,
      commitment: `${hrFloat(summary.generation_commit_pct * 100)}%`,
      generationMatched: `${Math.floor(Math.min(summary.allocated_generation_mwh)).toLocaleString()} MWh`,
      consumption: `${Math.ceil(summary.customer_consumption_mwh).toLocaleString()} MWh`,
      loadMatchedPct: summary.customer_consumption_mwh === 0 ? '-' : loadMatchedPct,
    }
  });
  return <div>
    <Title c="var(--color-blue-2)" fz="20px" fw="700" mb="24px" mt="24px">Allocation Details</Title>
    <BasePaper titleContent="Customer Overview">
      <BaseTable
        rows={rows}
        isLoading={programsRes.isFirstTimeLoading || customersRes.isFirstTimeLoading}
        columnNames={tableColumns}
        totalPages={0}
        currentPage={0}
        onPageChange={() => null}
      />
    </BasePaper>
  </div>
}

export default AllocationDetails;